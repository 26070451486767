import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { EnvService } from './services/env.service';
import { HttpService } from './services/http.service';
import { NotificationService } from './services/notification.service';
import { ValidatorsService } from './services/validators.service';

@NgModule({
  imports: [
    CommonModule,
  ],

  declarations: [],

  providers: [
    AuthService,
    TokenService,
    EnvService,
    HttpService,
    NotificationService,
    ValidatorsService
  ]
})
export class CoreModule { }
