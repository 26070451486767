import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './notification.component';

@NgModule({
    imports: [
        CommonModule, 
        MaterialModule, 
        ReactiveFormsModule 
    ],
    declarations: [ 
        NotificationComponent 
    ],
    exports: [ 
        NotificationComponent
    ]
})

export class NotificationModule {}
