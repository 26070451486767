import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor(
    private http: HttpService,
    private token: TokenService,
  ) { }

  adminExists(value: string, field: string) {
    field = field.replace(/[A-Z]/g, m => '_' + m.toLowerCase());
    const fields = field.split('_');
    let extra = '';
    fields.map(e => extra += e.charAt(0));
    return this.http.request('GET', 'adminExists', null, {extraUri: `${value}/${extra}`})
      .pipe(map(data => {
        return data;
      }));
  }

}
