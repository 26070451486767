import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { TokenService } from '../../../core/services/token.service';
import { EnvService } from '../../../core/services/env.service';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';

declare const $: any;

// Metadata
export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    ability?: string;
}

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    ability?: string;
}

// Menu Items
export const ROUTES: Array<RouteInfo> = [
    {
        path: '/dashboard',
        title: 'Tablero',
        type: 'link',
        icontype: 'dashboard',
        ability: 'tablero',
    },{
        path: '/manage-admins',
        title: 'Gestión de Usuarios',
        type: 'sub',
        icontype: 'supervisor_account',
        collapse: 'manage-admins',
        ability: 'gestion-de-usuarios',
        children: [
            {path: 'abilities', title: 'Privilegios', ab: 'P', ability: 'privilegios'},
            {path: 'roles', title: 'Perfiles', ab: 'P', ability: 'perfiles'},
            {path: 'admins', title: 'Usuarios', ab: 'U', ability: 'usuarios'},
        ]
    },{
        path: '/manage-goods',
        title: 'Gestión de Bienes',
        type: 'sub',
        icontype: 'document_scanner',
        collapse: 'manage-goods',
        ability: 'gestion-de-bienes',
        children: [
            {path: 'perceptive-control', title: 'Control Perceptivo', ab: 'C', ability: 'control-perceptivo'},
            {path: 'movement', title: 'Movimiento de Bien Nacional', ab: 'M', ability: 'movimiento'},
        ]
    },{
        path: '/manage-active',
        title: 'Activos Fijos',
        type: 'sub',
        icontype: 'qr_code',
        collapse: 'manage-active',
        ability: 'erogacion',
        children: [
            {path: 'depreciation', title: 'Depreciación/Amortización', ab: 'D', ability: 'depreciacion-amortizacion'},
            {path: 'erogation', title: 'Mejora', ab: 'M', ability: 'erogacion'},
        ]
    },{
        path: '/manage-tables/tables',
        title: 'Gestión de Tablas Maestras',
        type: 'link',
        icontype: 'settings',
        collapse: 'manage-tables',
        ability: 'gestion-de-tablas'
    },{
        path: '/historical-goods',
        title: 'Reportes',
        type: 'sub',
        icontype: 'folder_shared',
        collapse: 'historical-goods',
        ability: 'reportes',
        children: [
            {path: 'furniture-reports', title: 'Reporte Muebles', ab: 'RM', ability: 'furniture-reports'},
            {path: 'inmueble-reports', title: 'Reporte Inmuebles', ab: 'RI', ability: 'inmueble-reports'},
            {path: 'semoviente-reports', title: 'Reporte Semovientes', ab: 'RS', ability: 'semoviente-reports'},
            {path: 'vehicle-reports', title: 'Reporte Vehiculos', ab: 'RV', ability: 'vehicle-reports'},
        ],
    },{
        path: '/inventory',
        title: 'Inventario de Bienes',
        type: 'sub',
        icontype: 'inventory',
        collapse: 'inventory',
        ability: 'inventario',
        children: [
            {path: 'physical-holding', title: 'Toma Física', ab: 'TF', ability: 'toma-fisica'},
            {path: 'account-surrender', title: 'Rendicion de cuenta', ab: 'RC', ability: 'rendicion-cuenta'},
        ],
    },{
        path: '/audit',
        title: 'Auditoria del Sistema',
        type: 'sub',
        icontype: 'folder_shared',
        collapse: 'audit',
        ability: 'auditorias',
        children: [
            {path: 'transactional', title: 'Transaccional', ab: 'T', ability: 'transaccional'},
            {path: 'audit-users', title: 'Usuarios', ab: 'U', ability: 'auditoria-usuario'},
        ],
    },
];

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {

    public menuItems: any[];
    public appName = this.env.getOne('app');
    public user = this.token.get();
    ps: any;

    constructor(
        private token: TokenService,
        private env: EnvService,
        private router: Router,
        public auth: AuthService,
    ) { }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    logOut(event: MouseEvent) {
        event.preventDefault();
        this.auth.logOut();
    }
}
