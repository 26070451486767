import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apis } from '../apis/apis';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  constructor() { }

  api(server: string, api: string): string {
    const uri = environment.servers[server];
    const endpoint = apis[api];
    return uri + endpoint;
  }

  getOne(env): string {
    return environment[env];
  }

  getAll(): object {
    return environment;
  }
}
