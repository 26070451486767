import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { SidebarModule } from './components/shared/sidebar/sidebar.module';
import { NavbarModule } from './components/shared/navbar/navbar.module';
import { FooterModule } from './components/shared/footer/footer.module';
import { FixedpluginModule } from './components/shared/fixedplugin/fixedplugin.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './core/material/material.module';
import { NotificationModule } from './components/shared/notification/notification.module';

@NgModule({
    imports: [
      CommonModule,
      BrowserAnimationsModule,
      FormsModule,
      RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
      HttpClientModule,
      MaterialModule,
      SidebarModule,
      NavbarModule,
      FooterModule,
      FixedpluginModule,
      CoreModule,
      NotificationModule
    ],
    declarations: [
      AppComponent,
      AdminLayoutComponent,
      AuthLayoutComponent
    ],
    providers: [],
    bootstrap: [ AppComponent ]
})
export class AppModule { }