<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="/assets/img/washiru-brand.png"/>
    </div>
  </a>
  <a routerLink="/dashboard" class="simple-text logo-normal" matTooltip="{{appName}}" [matTooltipPosition]="'below'">
    {{appName | slice:0:20 }}...
  </a>
</div>
<div class="sidebar-wrapper">
  <div class="user">
    <div class="photo">
      <ngx-avatar name="{{user.fullname}}" size="34"></ngx-avatar>
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="nav-link">
        <p>
          {{user.fullname | slice:0:18}}{{user.fullname && user.fullname.length >= 18? '...': ''}}
          <b class="caret"></b>
        </p>
      </a>
      <div class="collapse" id="collapseExample">
        <ul class="nav mt-0">
          <li class="nav-item">
            <a routerLink="/profile" class="nav-link">
              <span class="sidebar-mini">MC</span>
              <span class="sidebar-normal">Mi Cuenta</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link" (click)="logOut($event)">
              <span class="sidebar-mini">CS</span>
              <span class="sidebar-normal">Cerrar Sesión</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
        <!--If is a single link-->
        <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link' && auth.hasAccess(menuitem.ability)" class="nav-link">
            <i class="material-icons">{{menuitem.icontype}}</i>
            <p matTooltip="{{menuitem.title}}" [matTooltipPosition]="'right'">{{menuitem.title | truncate}}</p>
        </a>
        <!--If it have a submenu-->
        <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub' && auth.hasAccess(menuitem.ability)" (click)="updatePS()" class="nav-link">
            <i class="material-icons">{{menuitem.icontype}}</i>
            <p matTooltip="{{menuitem.title}}" [matTooltipPosition]="'right'">{{menuitem.title | truncate}}<b class="caret"></b></p>
        </a>

        <!--Display the submenu items-->
        <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
            <ul class="nav">
                <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                    <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link" *ngIf="auth.hasAccess(childitem.ability)" matTooltip="{{childitem.title}}" [matTooltipPosition]="'right'">
                        <span class="sidebar-mini">{{childitem.ab}}</span>
                        <span class="sidebar-normal">{{childitem.title | truncate}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </li>
  </ul>
</div>