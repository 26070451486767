import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { AvatarModule } from 'ngx-avatar';
import { TruncatePipe } from '../../../core/pipes/truncate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [ RouterModule, CommonModule, AvatarModule, MatTooltipModule ],
    declarations: [ SidebarComponent, TruncatePipe ],
    exports: [ SidebarComponent ]
})

export class SidebarModule {}
