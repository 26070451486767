import { Injectable } from '@angular/core';
import { EnvService } from './env.service';

interface Payload {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
}

@Injectable()
export class TokenService {

  private item: string;

  private iss: any;

  constructor(private env: EnvService) {
    this.iss = this.env.getOne('servers');
    this.item = `user${this.env.getOne('app').split(' ')[0]}`;
  }

  handle(token): void {
    this.set(token);
  }

  set(token): void {
    localStorage.setItem(this.item, JSON.stringify(token));
  }

  get(field: string = 'user'): any {
    const data = JSON.parse(localStorage.getItem(this.item));
    if (data) {
      return data[field];
    }
    return data;
  }

  remove(): void {
    localStorage.removeItem(this.item);
  }

  isValid(): boolean {
    // Validate if token does not' exists
    const token = this.get('access_token');
    if (!token) {
      return false;
    }
    // Validate if don't' token was generated by our backend
    const payload = this.payload(token);
    const servers = Object.values<string>(this.iss);
    if ( servers.filter(s => !payload.iss.startsWith(s)).length > 0 ) {
      return false;
    }
    // Validate if token has expired
    const currentDate = new Date();
    const expiresIn = new Date(payload.exp * 1000);
    if (currentDate >= expiresIn) {
      return false;
    }
    // It's ok
    return true;
  }

  payload(token): Payload {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(paylaod): Payload {
    return JSON.parse(atob(paylaod));
  }

  loggedIn(): boolean {
    return this.isValid();
  }
}
