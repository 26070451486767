export const apis = {

    // invokes
    adminExists: '/users/exists/',

    // auth
    login: '/login/',
    passwordEmail: '/password/email/',
    passwordReset: '/password/reset/',
    generateQr: '/generate-qr/',
    refresh: '/refresh/',
    logout: '/auth/logout/',

    // manage admins
    abilities: '/abilities',
    roles: '/roles',
    admins: '/admins',
    users: '/users',
    password: '/password/reset',

    // Catalogos
    catalogues: '/api/catalogues',

    // Applicants
    applicant: '/applicant/',
    applicantFiles: '/applicant-files/',

    // master tables
    countries: '/administration/country',
    currencies: '/administration/currency',
    physicalConditions: '/administration/physical-condition',
    useStates: '/administration/use-state',
    states: '/administration/state',
    municipalities: '/administration/municipality',
    parishes: '/administration/parish',
    marks: '/administration/mark',
    models: '/administration/model',
    colors: '/administration/color',
    goodsTypes: '/administration/goods-type',
    livestockTypes: '/administration/livestock-type',
    movementTypes: '/administration/movement-type',
    disincorporationCauses: '/administration/disincorporation-cause',
    occupationStates: '/administration/occupation-state',
    currentUsages: '/administration/current-usage',
    acquisitionForms: '/administration/acquisition-form',
    authorizedSignatures: '/administration/authorized-signature',
    measurementUnits: '/administration/measurement-unit',
    breeds: '/administration/breed',
    accountingCodes: '/administration/accounting-code',
    deliveryMethods: '/administration/delivery-method',
    variableStatus: '/administration/variable-status',
    modality: '/administration/modality',
    reasonRejection: '/administration/reason-rejection-property',
    auxiliaries: '/administration/auxiliary',
    reports: '/administration/report',
    reasonMovementTypes: '/administration/reason-movement-type',
    documents: '/administration/document',
    headquarters: '/administration/headquarter',
    administrativeUnits: '/administration/administrative-unit',
    reasonMovements: '/administration/reason-movement',
    requiredDocuments: '/administration/required-document',
    generalCategories: '/administration/general-category',
    specificCategories: '/administration/specific-category',
    subcategories: '/administration/sub-category',
    positions: '/administration/position',
    activeType: '/administration/active-type',
    suppliers: '/administration/supplier',
    organismData: '/administration/organism-data',
    organismPublic: '/administration/organism-public',
    region: '/administration/region',
    proficient: '/administration/proficient',
    gender: '/administration/gender',
    searchSupplier: '/administration/search/supplier',
    institute: '/administration/institute',
    reasonReassignment: '/administration/reason-reassignment',
    reasonPartialDelivery: '/administration/reason-partial-delivery',
    reasonDisagreement: '/administration/reason-disagreenment',
    reasonReturned: '/administration/reason-returned',
    reasonDeallocation: '/administration/reason-disassignment',

    // bienes
    perceptiveControl: '/goods/scp',
    scpUpload: '/scp/upload-files',
    upload: '/general/upload-files',
    inventoryCheck: '/goods/inventory-check',
    generalProperty: '/goods/general-property',
    goodType: '/modality-good-type',
    returnAplication: '/incorporation/return-application',
    audit: '/administration/audit',
    historical: '/goods/historical-reports',
    dashboard: '/dashboard',

    // comiso
    createComiso: '/goods/scp-seizure',
    createLineComiso: '/goods/scp-seizure-product',

    //verificacion 
    docIdentity: '/api/download-file',
    identityVerification: '/identity-verification',

    //payment    
    accounts: '/mission-accounts',
    missionPaymentMethods: '/mission-payment-methods',
    missionDevices: '/mission-devices',
    missionMonetaryCones: '/mission-monetary-cones',
    missionCryptocurrencies: '/mission-cryptocurrencies',
    paymentManagement: '/payment-management',
    paymentManagementPending: '/payment-management?slug=pending',    

    // documentos
    uploadFiles: '/applicant/upload-files',
    deleteFiles: '/scp/delete-files',

    // asignar y reasignar
    usersAsig: '/goods/assignment',
    usersAsigConformidad: '/accordance/officers-involved',

    // facturas
    facture: '/goods/invoce-scp',
    invoice: '/goods/invoce-list',

    // desglose
    desglose: '/accordance/line-breakdown',
    documentAprove: '/accordance/required-document-aprove',
    uploadFilesDesglose: '/accordance-upload-file',
    conformidad: '/accordance/property-management',
    usersAsigDesglose: '/accordance/officer-present',
    finishConformidad: '/accordance/request',
    acp: '/accordance/generate-acp',
    allDesglose: '/accordance/goods-lines',
    incorporation: '/accordance/incorporation',
    pdfIncorporation: '/incorporation/vaucher',
    goodNumber: '/accordance/good-number',
    validationVehicle: '/accordance/check-goods',
    
    // descargara documento
    documentDowload: '/scp-download-files',

    // transferencia
    listTransfer: '/request-transfer',
    managementGood: '/transfer/',
    photo: '/transfer/upload-img',
    documentRequire: '/movement/requirement',
    documentTransfer: '/transfer/upload-files',

    // reasignnacion
    sendReassignment: '/request-reassignment',
    receivedReassignment: '/reassignment-received',
    sede: '/reassignment/head-quarter-dependency',
    responsible: '/reassignment-get-responsible',
    listGoodReassig: '/reassignment/reassignment-add-good',
    goodReassig: '/reassignment/good-dependency',
    saveReassig: '/reassignment-save',
    sentReassig: '/reassignment-sent',
    confirGood: '/reassignment-confirm-good',
    confirReassig: '/reassignment-confirm',
    excelReassig: '/reassignment/download-list',
    excelReassigReceived: '/reassignment/download-list-received',
    excelGoodReassig: '/reassignment/download-list-good',
    actaReassig: '/reassignment/download-voucher',
    uploadDocumentReassig: '/reassignment-upload-files',
    downloadFile: '/reassignment/download-files',

    // mi bienes
    listGood: '/my-goods',
    editGood: '/my-goods/edit',
    listUser: '/goods/assignment',
    asigna: '/my-goods/assignment',
    unasigna: '/my-goods/unassignment',
    listGoodUser: '/user-goods',
    asignaUser: '/user-goods-assignment',
    downloadListGood: '/my-goods/download-excel',
    actaResponsible: '/responsbile/download-voucher',

    // enajenacion
    listAlienation: '/request-alienation',
    // managementAlienationGood: '/alienation/',
    photoAlienation: '/transfer/alienation-upload-img',
    documentAlienationRequire: '/movement/requirement',
    documentAlienation: '/transfer/alienation-upload-files',
    modalityAlienation: '/transfer-modality',
    proficientAlineation: '/alienation/proficient-list',

    // incorporacion
    modalityIncorporation: '/incorporation-modality',
    privateEntity: '/administration/private-entity',

    apiDownload: '/general/download-files',
    qrRange: '/accordance/print-qr-range',
    reasonMovement: '/reason-movement',

    // desincorporacion
    reasignaDesincorporation: '/disincorporation/reassignment-received',
    confirReasignaDesincorporation: '/resignment-disincorporation',
    goodDesincorporatio: '/disincorporation/reassignment-good',
    desincorporation: '/goods/disincorporation',
    excelReassigDesincor: '/reassignment/download-list-disincorporation',
    photoReassig: '/reassignment/reassignment-upload-img',
    listPhoto: '/reassignment/reassignment-good-img-list',
    managementGoodDesincorporation: '/disincorporation/',
    downloadDesincorporation: '/disincorporation/disincorporation-download-files',
    uploadDesincorporation: '/disincorporation/disincorporation-upload-files',
    photoDesincorporation: '/disincorporation/disincorporation-upload-img',
    listPhotoDesincorporation: '/disincorporation/disincorporation-good-img-list',
    ficha: '/goods/vehicle-disincorporation',
    listFicha: '/goods/get-vehicle-disincorporation/',
    deleteSolicitud: '/disincorporation/disincorporation-add-good/',
    downloadBienes: '/disincorporation/goods-disincorporation-list',
    downloadSolicitud: '/disincorporation/disincorporation-download-list',
    downloadActa: '/disincorporation/download-voucher',
    downloadGoodSolicitud: '/disincorporation/disincorporation-download-list-good',
    listarAsigna: '/disincorporation/assignment-reassignment',

    // user 
    ldap: '/users/registered',
    userPresent: '/users/official-present',

    // depreciation
    depreciation: '/depreciation-armortization',
    calcular: '/depreciation-calculation',
    resumen: '/depreciation-detail',
    depreciacionPDF: '/depreciation-armortization/download-pdf-list',
    depreciacionExcel: '/depreciation-armortization/download-excel-list',
    resumenExcel: '/depreciation-armortization/download-excel-detail',
    resumenPDF: '/depreciation-armortization/download-pdf-detail',
    calculoExcel: '/depreciation-armortization/download-excel-calculation',
    calculoPDF: '/depreciation-armortization/download-pdf-calculation',
    Filterdepreciation: '/get-depreciation-armortization',
    
    // erogation
    erogation: '/disbursement',
    excelErogation: '/disbursement/download-excel-list',
    pdfErogation: '/disbursement/download-pdf-list',
    consultarBien: '/good/exists',
    
    erogationType: '/administration/disbursement-type',

    documentReasignaRequiere: '/reassignment/required-document',

    // reportes activos
    listReport: '/depreciation-report-list',
    itemReport: '/depreciation-armortization-report',
    pdfReport: '/fixed-asset/download-pdf',
    excelReport: '/fixed-asset/download-excel',

    busqueda: '/api/specific-category/code',
    
};
