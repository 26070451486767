import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'src/app/components/shared/notification/notification.component';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

@Injectable()

export class AuthService {

  private loggedIn = new BehaviorSubject<boolean> (this.token.loggedIn());

  status = this.loggedIn.asObservable();
  snackBarRef: any;

  constructor(
    private token: TokenService,
    private http: HttpService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialog
  ) {
    this.status.subscribe(value => {
      if(value){this.expiresSesionTime()}
    })
  }

  change(value: boolean) {
    this.loggedIn.next(value);
  }

  login(username: string, password: string, api: string = 'login'): Observable<boolean> {
    const data = {username, password};
    return this.http.request('POST', api, data, { server: 'auth' })
      .pipe(map(response => {
        if(response){
          this.expiresSesionTime(response);
          return response;
        }
      }));
  }

  logOut() {
    this.token.remove();
    this.change(false);
    this.router.navigate(['/']);
    this.dialogRef.closeAll();
    this._snackBar.dismiss();
  }

  // hasAccess(ability: string) {
  //   if (ability === undefined) {
  //       return true;
  //   }
  //   const user = this.token.get();
  //   if (user.isAdmin) return true;
  //   return user.abilities.filter(e => {
  //     if (e.name === '*' || e.name === ability) {
  //       return e;
  //     }
  //   }).length > 0;
  // }

  hasAccess(ability: string) {
    if (ability === undefined) {
        return true;
    }
    const user = this.token.get();
    return user.abilities.filter(e => {
      if (e.name === '*' || e.name === ability) {
        return e;
      }
    }).length > 0;
    console.log(ability);
  }
  
  expiresSesionTime(token?) {
    const dateNow = new Date();
    const expires_in = (token)? new Date(token.expires_in): new Date(this.token.get('expires_in'));
    // const diff = moment.utc(moment(expires_in,"DD/MM/YYYY HH:mm:ss").diff(moment(dateNow,"DD/MM/YYYY HH:mm:ss"))).minutes();
    const diff = moment.duration(moment(expires_in).diff(dateNow));
    const timer = diff.asMinutes() - 2;
    if (timer > 3) {
      setTimeout(() => {
        this.notification('Su sesión está por expirar, ¿desea renovarla?');
      }, timer * 60000);
    } else if(timer <= 3 && timer >= 0) {
      this.notification('Su sesión está por expirar, ¿desea renovarla?', timer)
    } else if (timer < 0) {
      this.notification('Su sesión está por expirar, ¿desea renovarla?', 0)
    }
  }

  notification(message?: string, timer?) {    
    this.snackBarRef = this._snackBar.openFromComponent(NotificationComponent, {
      data: { message, action: true, cancel: true },
      duration: 120000,
      panelClass: ['mat-toolbar', 'mat-primary', 'sgc-snack-bar']
    });

    this.snackBarRef.onAction().subscribe(() => {
      this.refreshSession();      
    });

    setTimeout(() => {            
      this.logOut();
    }, timer * 60000);
  }

  refreshSession() {    
      this.http.request('POST', 'refresh', null)
        .subscribe(response => {
          this.token.handle(response)
          this.expiresSesionTime(response);
      },
      error => {
        console.log(error, 'error');
      });
  }
  
}
