import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './pages/pages.module#PagesModule'
            }
        ]
    }, {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './components/dashboard/dashboard.module#DashboardModule'
            }, {
                path: '',
                loadChildren: './components/profile/profile.module#ProfileModule'
            }, {
                path: 'manage-admins',
                loadChildren: './components/manage-admins/manage-admins.module#ManageAdminsModule'
            }, {
                path: 'manage-goods',
                loadChildren: './components/manage-goods/manage-goods.module#ManageGoodsModule'
            }, {
                path: 'manage-active',
                loadChildren: './components/manage-active/manage-active.module#ManageActiveModule'
            }, {
                path: 'manage-tables',
                loadChildren: './components/manage-tables/manage-tables.module#ManageTablesModule'
            }, {
                path: 'inventory',
                loadChildren: './components/inventory/inventory.module#InventoryModule'
            }, {
                path: '',
                loadChildren: './components/identity_verification/identity_verification.module#IdentityVerificationModule'
            }, {
                path: '',
                loadChildren: './components/user-file/user-file.module#UserFileModule'
            }, {
                path: 'payment',
                loadChildren: './components/payment/payment.module#PaymentModule'
            }, {
                path: 'audit',
                loadChildren: './components/audit/audit.module#AuditModule'
            },
            {
                path: 'historical-goods',
                loadChildren: './components/historical-goods/historical-goods.module#HistoricalGoodsModule'
            },
        ],
        canActivate: [AuthGuard]
    },{
        path: '**',
        redirectTo: 'dashboard'
    }
];
